<template>
    <v-container class="slider-container">
        <v-chip label outlined color="white">
            {{currentIndex + 1}}/{{images.length}}
        </v-chip>
        <v-carousel class="my-slider" hide-delimiters v-model="currentIndex">
            <v-carousel-item v-for="(image, i) in images" :key="i" :src="image" />
            <template v-slot:prev="{ on, attrs }">
                <v-btn icon outlined v-bind="attrs" v-on="on">
                    <v-icon>{{ mdiArrowLeftBoldOutline }}</v-icon>
                </v-btn>
            </template>
            <template v-slot:next="{ on, attrs }">
                <v-btn icon outlined v-bind="attrs" v-on="on">
                    <v-icon>{{ mdiArrowRightBoldOutline }}</v-icon>
                </v-btn>
            </template>
        </v-carousel>
    </v-container>
</template>

<script>
import { mdiArrowLeftBoldOutline, mdiArrowRightBoldOutline } from "@mdi/js";

export default {
    name: "ImageCarousel",

    props: ["images", "imageIndex"],

    data() {
        return {
            mdiArrowLeftBoldOutline: mdiArrowLeftBoldOutline,
            mdiArrowRightBoldOutline: mdiArrowRightBoldOutline,
            currentIndex: this.imageIndex
        };
    },

    watch: {
        imageIndex(val) {
            this.currentIndex = val;
        }
    }
};
</script>

<style lang="scss">
.slider-container {
    height: 100% !important;
}
.my-slider {
    height: 100% !important;
    .v-image__image {
        background-size: contain;
    }
    .v-image {
        height: 90% !important;
        width: 100% !important;
    }
}
</style>